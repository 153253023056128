export enum ActionEnum {
  SET_COUNTRY_CODE = 'SET_COUNTRY_CODE',
  SET_ALL_STATE = 'SET_ALL_STATE',
  SET_PAGE_LOADING = 'SET_PAGE_LOADING',
  SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
  SET_ORDERINFO = 'SET_ORDERINFO',
  SET_SPECIAL_COUNTRY_CODE = 'SET_SPECIAL_COUNTRY_CODE',
  SET_CURRENT_STUDENT_INFO = 'SET_CURRENT_STUDENT_INFO',
  SET_COUPON_INFO = 'SET_COUPON_INFO',
  SET_SUBSCRIPTION_PRICE_INFO = 'SET_SUBSCRIPTION_PRICE_INFO',
  SET_BILLING_COUNTRY = 'SET_BILLING_COUNTRY',
  SET_FORM_VALIDATOR = 'SET_FORM_VALIDATOR',
  SET_COMMON_PAYLIST = 'SET_COMMON_PAYLIST',
  SET_CARD_FORM = 'SET_CARD_FORM',
}
/**
 * 订单购买产品信息
 */

export type AWPaymentMethod =
  | 'CARD'
  | 'WECHAT'
  | 'ALIPAY'
  | 'ALIPAY_HK'
  | 'PAY_NOW'
  | 'DANA'
  | 'GCASH'
  | 'KAKAO'
  | 'TRUEMONEY'
  | 'SOFORT'
  | 'GIROPAY'
  | 'IDEAL'
  | 'TNG';
export interface OrderLine {
  /**
   * 购买数量
   */
  quantity: number;
  /**
   * 主键
   */
  uuid: string;
  /**
   * 产品快照
   */
  productSnapshot: {
    name: string;
  };
}

export enum CountryToCode {
  'Austria' = 'AT',
  'Belgium' = 'BE',
  'Switzerland' = 'CH',
  'Germany' = 'DE',
  'Spain' = 'ES',
  'United Kiongdom' = 'GB',
  'Italy' = 'IT',
  'Netherlands' = 'NL',
  'Poland' = 'PL',
}

export enum BanksUpperCase {
  'Rabobank' = 'rabobank',
  'ABN AMRO' = 'abn_amro',
  'Van Lanschot' = 'van_lanschot_baniers',
  'Triodos Bank' = 'triodos',
  'NG' = 'ng',
  'SNS' = 'sns',
  'ASN Bank' = 'asn',
  'RegioBank' = 'regiobank',
  'Knab' = 'knab',
  'Bunq' = 'bunq',
  'Svenska Handelsbanken' = 'handelsbanken',
  'Revolut' = 'revolut',
}

// 获取订单 接口 返回数据 (首页订单 发起支付接口)
export type OrderProps = {
  /**
   * 订单id
   */
  uuid?: string;
  money?: {
    // 金额
    value: number;
    // 支付货币
    currency: PayType;
  };
  // 产品信息
  orderLines?: OrderLine[];
  // 支付结果的订单号
  orderNumber?: string;
  // （发起支付接口)
  payment?: {
    uuid: string;
    // 支付时间
    whenModified?: string;
    // 支付方式
    paymentMethod?: string;
    /**
     * 支付状态
     */
    paymentStatus: PaymentStatus;
    customMessage: {
      nonce: string;
      // PAYPAL 跳转路径
      papal_url: string;
      // 微信 支付宝 跳转路径 host_url + nonce
      host_url: string;
    };
    // 转换
    exchangeRate: number;
    // 实际支付
    paymentAmount: number;
    // 货币
    currency: Currency;
  };
  // userId
  customerId?: string;
  message?: string;
  // 订单状态
  orderStatus?: OrderStatus;
  orderShipment?: {
    countryCode?: 'AU' | 'NZ' | 'US';
    receiverName: string;
    studentCode: string;
    receiverPhone?: string;
  };
  taxInvoice?: {
    invoiceNumber?: string;
  };
  expireTime?: string;
  actualPaymentAmount?: OrderProps['money'];
  remainingAmount?: OrderProps['money'];
};
// url 传入的 orderId userId
export interface UrlUserPrams {
  // isRedirect?: number;
  orderId?: string;
  userId?: string;
  // 返回地址
  returnUrl?: string;
  // 结果页数据
  reference?: string;
  // 银行转账的数字
  invoiceNo?: string;
  // 支付方式 结果页用
  paymentMethod?: PaymentMethod;
  // paypal 支付方式 返回参数
  token?: string;
  // 网关使用方
  paySource?: string;
  /**
   * 支付id
   */
  paymentId?: string;
  countrys?: (keyof typeof CountryToCode)[]; // aw支付的一些额外参数
  shopperName?: string;
  method?: PayMethods;
  countryCode?: string;
  /**
   * 二维码数据
   */
  qrCode?: string;
}

export interface IndexProps {
  // 订单信息
  orderInfo?: OrderProps;
  // 服务端渲染抛出错误
  orderApiErr?: string;
  /**
   * klarna可用币种
   */
  klarnaAvailableCurrency: ExchangeRate[];
  /**
   * atome可用币种
   */
  atomeAvailableCurrency: ExchangeRate[];
  agreementUrl?: string;
  isRedirect?: number;
  payMethod?: PayMethods;

  exchangeRate?: ExchangeRate;

  studentCode?: string;

  subscribeConfigId?: string; // 订阅课包
}

// 请求接口参数
export interface PaymentInitParams {
  orderId?: string;
  userId?: string;
  paymentMethod?: string;
  tokenId?: string;
  // paypay使用
  token?: string;
  // 网关使用方
  paySource?: string;
  paymentId?: string;
}
export interface PaymentInitKlarna {
  orderId: string;
  token: string;
}
// paymentStatus PENDING 继续轮训 FAILED错误 SUCCESSFUL成功 | 待确认
export type PaymentStatus = 'PENDING' | 'FAILED' | 'SUCCESSFUL' | 'PENDING_CONFIRMATION';
// 订单状态 已支付 | 未支付 | 已取消 | 支付中 | 支付中 | 待支付
export type OrderStatus = 'PAYMENT_RECEIVED' | 'PENDING_PAYMENT' | 'CANCELLED' | 'CONFIRMED' | 'PARTIAL_PAYMENT';
type PaymentMethod = 'ALIPAY' | 'WECHAT' | 'PAYPAL' | 'STRIPE' | 'CARD' | 'CITCON';
// 结果页接口返回数据
export type ResultData = {
  Amount?: string;
  OrderID?: string;
  Payment_Time?: string;
  Product?: {
    quantity: number;
    uuid: string;
    productSnapshot: {
      name: string;
    };
  }[];
  // 是 PENDING的时候 代表继续轮询 ;其他情况停止轮询 包括接口报错
  // 支付状态
  paymentStatus?: PaymentStatus;
  Payment_Method?: PaymentMethod;
  message?: string;
};

// 结果页 默认图片 默认字段
export enum ImgResultData {
  SUCCESSFUL = '/icon/gs/pic-successful.svg',
  FAILED = '/icon/gs/pic-failed.svg',
  // PENDING = '/icon/pic-default.png',
  PENDING = '/icon/pic-pending-confirmation.gif',
  PENDING_CONFIRMATION = '/icon/pic-pending-confirmation.gif',
  DEFAULT = '/icon/pic-pending-confirmation.gif',
}
export enum TitleResultData {
  SUCCESSFUL = 'Payment Successful',
  FAILED = 'Payment Failed',
  // PENDING = '',
  PENDING = 'Confirming Payment Status...',
  PENDING_CONFIRMATION = 'Confirming Payment Status...',
  DEFAULT = 'Confirming Payment Status...',
}
export enum SubscriptionTitleResultData {
  SUCCESSFUL = 'Update Successful',
  FAILED = 'Update Failed',
  // PENDING = '',
  PENDING = 'Confirming Payment Status...',
  PENDING_CONFIRMATION = 'Confirming Payment Status...',
  DEFAULT = 'Confirming Payment Status...',
}
export const descResultData = {
  SUCCESSFUL: () => `Congratulations! You have successfully purchased the Wukong course.`,
  FAILED: () => 'Please contact your consultant if you have any questions',
  PENDING: () => 'Please contact your consultant if you have any questions',
  PENDING_CONFIRMATION: () => 'Please contact your consultant if you have any questions',
  DEFAULT: () => 'Please contact your consultant if you have any questions',
};
export const descResultDataOfSubscribe = {
  SUCCESSFUL: (subject: string) =>
    `Congratulations! You have successfully subscribed to a Wukong ${subject} Language course.`,
  FAILED: () => 'Please contact your consultant if you have any questions',
  PENDING: () => 'Please contact your consultant if you have any questions',
  PENDING_CONFIRMATION: () => 'Please contact your consultant if you have any questions',
  DEFAULT: () => 'Please contact your consultant if you have any questions',
};
export enum TitleDataOfSubscribe {
  SUCCESSFUL = 'Payment Successful',
  FAILED = 'Payment Failed',
  // PENDING = '',
  PENDING = 'Payment Confirmation',
  PENDING_CONFIRMATION = 'Payment Confirmation',
  DEFAULT = 'Payment Confirmation',
}
export enum ButtonResultData {
  SUCCESSFUL = 'success',
  FAILED = 'danger',
  PENDING = 'success',
  PENDING_CONFIRMATION = 'success',
  DEFAULT = 'default',
}
// 货币方式 新西兰元 NZD 人民币CNY 美元USD
export type PayType = 'NZD' | 'CNY' | 'USD' | 'AUD' | 'DEFAULT';

/** 支付方式 */
export type PayMethods =
  | 'BankTransfer'
  | 'WECHAT'
  | 'ALIPAY'
  | 'STRIPE'
  | 'PAYPAL'
  | 'UNION_PAY'
  | 'AW_CARD'
  | 'AW_ALIPAY'
  | 'KLARNA'
  | 'AW_WECHAT'
  | 'LATIPAY'
  | 'OTHER'
  | 'AIRWALLEX'
  | 'UPOP'
  | 'INSTALLMENT'
  | 'ATOME'
  | 'DANA'
  | 'GCASH'
  | 'KAKAO'
  | 'TNG'
  | 'TRUEMONEY'
  | 'SOFORT'
  | 'GIROPAY'
  | 'IDEAL'
  | 'PINGPONG'
  | 'VENMO'
  | 'CITCON'
  | 'CASH_APP'
  | 'ALIPAY_HK'
  | 'PAY_NOW'
  | 'ZERO';

export enum CurrencySymbolSwitch {
  NZD = '$',
  CNY = '¥',
  USD = '$',
  AUD = '$',
  DEFAULT = '',
}
// 首页 每项支付方式的数据类型
export interface InterPayList {
  title: string;
  src: string;
  key: PayMethods;
  hide?: boolean;
  country?: string[];
  after?: React.ReactNode;
  paramsKey?: string;
  // New User Offer 标识
  newOffer?: boolean;
  // 支付表单 提醒
  listTips?: string;
  tag?: string;
  remind?: string;
  currencys?: { [x: string]: string[] };
  quotaCurrencys?: string;
  min?: number;
  max?: number;
}
// 映射 后台返回支付方式
export enum PaymentMethodSwitch {
  ALIPAY = 'Alipay',
  WECHAT = 'WeChat Pay',
  PAYPAL = 'PayPal',
  STRIPE = 'Stripe',
  CARD = 'Card',
  UPOP = 'Union Pay',
  OTHER = 'Other',
  KLARNA = 'Klarna',
}

export enum Currency {
  USD = 'USD',
  CNY = 'CNY',
  NZD = 'NZD',
  AUD = 'AUD',
  HKD = 'HKD',
  EUR = 'EUR',
  GBP = 'GBP',
  CHF = 'CHF',
  SGD = 'SGD',
  IDR = 'IDR',
  MYR = 'MYR',
  CAD = 'CAD',
  JPY = 'JPY',
  OTHERS = 'OTHERS',
}

export const CurrencySymbol = {
  USD: '$',
  CNY: '￥',
  NZD: 'NZ$',
  AUD: 'A$',
  HKD: 'HK$',
  EUR: '€',
  GBP: '£',
  CHF: '₣',
  SGD: 'S$',
  IDR: 'Rp',
  MYR: 'RM',
  CAD: 'C$',
  KRW: '₩',
  THB: '฿',
  JPY: '￥',
  OTHERS: '$',
};

export interface Money {
  currency: Currency;
  value: number;
}

/**
 * 汇率换算结果
 */
export interface ExchangeRate {
  /**
   * 原币种金额
   */
  source: Money;
  /**
   * 汇率转换后金额
   */
  target: Money;
  /**
   * 汇率
   */
  rate: number;
}

/**
 * klarna支付初始化参数
 */
export interface IKlarnaInitCustomMessage {
  /**
   * 国家代码(klarna)
   */
  countryCode: string;
  /**
   * 使用的语言(klarna)
   */
  locale: string;
}

/**
 * airwallex支付初始化参数
 */
export interface AirwallexInitCustomMessage {
  /**
   * 支付成功跳转地址
   */
  returnUrl?: string;
  /**
   * 支付方式
   */
  method: AWPaymentMethod;

  methodParams?: {
    [x: string]: any;
  };
}

/**
 * latipay支付初始化参数
 */
export interface LatipayInitCustomMessage {
  /**
   * 跳转地址
   */
  returnUrl: string;
  /**
   * 支付方法
   */
  method: 'wechat' | 'alipay' | 'upi_upop';
}

export interface PaypalInitCustomMessage {
  /**
   * 支付成功跳转地址
   */
  returnUrl: string;
  /**
   * 取消支付跳转地址
   */
  cancelUrl: string;
}

export interface StripeInitCustomMessage {
  /**
   * 支付的token
   */
  tokenId: string;
}
/**
 * Atome支付信息
 */
export interface AtomeInitCustomeMessage {
  /**
   * 支付成功跳转界面
   */
  paymentResultUrl: string;
  /**
   * 支付取消跳转地址
   */
  paymentCancelUrl: string;
}

export enum CitConPayMethod {
  UPOP = 'UPOP',
  WECHAT = 'WECHAT',
  ALIPAY = 'ALIPAY',
  PAYPAL = 'PAYPAL',
  VENMO = 'VENMO',
  CASH_APP = 'CASH_APP',
}
export interface CitConInitCustomeMessage {
  payMethod: CitConPayMethod;
  countryName: 'US'; // 必填US
  stateName?: string; // 非必填  stateName，cityName，zipCode要么都写，要么都不写
  cityName?: string; // 非必填
  zipCode?: string; // 邮编（非必填），以前没填就填，填了就不填
  currency?: Currency; // 货币
  paidRedirectUrl?: string;
  payFailRedirectUrl?: string;
  payCancelRedirectUrl?: string;
  useRedirect?: boolean; // 是否需要跳转链接， true - 是，false - 否
}

/**
 * 支付第三方初始化参数
 */
export type CustomMessageInit =
  | AirwallexInitCustomMessage
  | IKlarnaInitCustomMessage
  | LatipayInitCustomMessage
  | PaypalInitCustomMessage
  | StripeInitCustomMessage
  | AtomeInitCustomeMessage
  | CitConInitCustomeMessage;

/**
 * 支付初始化参数
 */
export interface PaymentInitReq<T = CustomMessageInit> {
  /**
   * 订单主键
   */
  orderId: string;
  /**
   * 支付渠道
   */
  paymentMethod: PayMethods;
  /**
   * 支付使用的币种
   */
  currency?: Currency;
  /**
   * 第三方支付初始化参数
   */
  customMessage: T;
  /**
   * 实际支付方式
   */
  standardPaymentMethod?: string;
}

export const CountryToCurrency: { [x: string]: string[] } = {
  AT: ['EUR'],
  BE: ['EUR'],
  DE: ['EUR'],
  ES: ['EUR'],
  IT: ['EUR'],
  NL: ['EUR'],
  PL: ['EUR'],
  CH: ['EUR', 'CHF'],
  GB: ['GBP'],
  JP: ['JPY'],
};

export const klarnaCountryMap = [
  { country: 'CA', currency: 'CAD' },
  { country: 'US', currency: 'USD' },
  { country: 'AT', currency: 'EUR' },
  { country: 'DK', currency: 'DKK' },
  { country: 'DE', currency: 'EUR' },
  { country: 'NL', currency: 'EUR' },
  { country: 'NO', currency: 'NOK' },
  { country: 'SE', currency: 'SEK' },
  { country: 'CH', currency: 'CHF' },
  { country: 'BE', currency: 'EUR' },
  { country: 'ES', currency: 'EUR' },
  { country: 'IT', currency: 'EUR' },
  { country: 'FI', currency: 'EUR' },
  { country: 'CZ', currency: 'CZK' },
  { country: 'FR', currency: 'EUR' },
  { country: 'IE', currency: 'EUR' },
  { country: 'PT', currency: 'EUR' },
  { country: 'PL', currency: 'PLN' },
  { country: 'GR', currency: 'EUR' },
  { country: 'GB', currency: 'GBP' },
];

/**
 * airwallex 第三方支付信息
 */
export interface AirwallexCustomMessage {
  next_action: {
    qrcode_url: string;
    url: string;
  };
  client_secret: string;
  id: string;
  customer_id: string;
}

export interface PaypalCustomMessage extends Record<string, string | number> {
  papal_url: string;
}

export interface KlarnaCustomMessage {
  client_token: string;
  bizContent: {
    action: {
      paymentRedirectUrl: string;
    };
  };
}

export interface LatipayCustomMessage {
  host_url: string;
  nonce: string;
}

export interface AtomeCustomeMessage {
  redirectUrl: string;
}

export interface CitConCustomeMessage {
  data: {
    payment: {
      method: string;
      client: Array<{
        client: string;
        format: string;
        method: string;
        content: string;
      }>;
    };
  };
  expireTime: string;
}

export type CustomMessage =
  | AirwallexCustomMessage
  | PaypalCustomMessage
  | KlarnaCustomMessage
  | LatipayCustomMessage
  | AtomeCustomeMessage
  | CitConCustomeMessage;

/**
 * 支付信息
 */
export interface PaymentRespDto<T = CustomMessage> {
  /**
   * 支付主键
   */
  uuid: string;
  /**
   * 订单id
   */
  orderId: string;
  /**
   * 支付状态
   */
  paymentStatus: PaymentStatus;
  /**
   * 支付金额
   */
  paymentAmount: number;
  /**
   * 付款时间
   */
  payTime: string;
  /**
   * 支付使用的币种
   */
  currency: Currency;
  /**
   * 支付方法
   */
  paymentMethod: PaymentMethod;
  /**
   * 标准支付方式
   */
  standardPaymentMethod: string;
  /**
   * 用户使用的支付方式
   */
  userPaymentMethod: string;
  /**
   * 创建时间
   */
  whenCreated: string;
  /**
   * 第三方支付信息
   */
  customMessage: T & { referenceCode?: string };
}

export type IPackageItem = {
  productSnapshot: {
    uuid: string;
    name: string;
    physicalGoods: boolean | null;
  };
};

export interface ISnapshotRespDTO {
  name: string;
  physicalGoods: boolean | null;
  packageItems: IPackageItem[];
}

export interface IOrderLineRespDTO {
  uuid: string;
  discount: number;
  quantity: number;
  productSnapshot: ISnapshotRespDTO;
}

/**
 * 订单信息
 */
export interface OrderRespDto<T = CustomMessage> {
  /**
   * 订单主键
   */
  uuid: string;
  /**
   * 订单编号
   */
  orderNumber: string;
  /**
   * 订单金额
   */
  money: Money;
  /**
   * 支付信息
   */
  payment?: PaymentRespDto<T>;
  /**
   * 购买的产品
   */
  orderLines: IOrderLineRespDTO[];
  orderShipment: {
    receiverName: string;
    receiverPhone: string;
  };
  expireTime: string | null;
  tags: string[];
  // 订单状态
  orderStatus?: OrderStatus;

  subject?: string;

  classType?: string;

  timezoneStd?: string;

  courseDuration?: number;
}

export interface IPaymentResponse {
  orderId: ''; // 订单Id
  orderNumber: ''; // 订单Id
  paymentStatus: ''; // 支付状态
  /** PaymentStatus {
          PENDING,
          PENDING_REMITTANCE,
          PENDING_CONFIRMATION,
          PARTIAL,
          SUCCESSFUL,
          FAILED,
          REFUNDED;｝ */
  currency: ''; // 币种
  amount: ''; // 支付量
  paymentTime: ''; // 支付时间
  timezoneStd: ''; // 学生时期
  productName: ''; // 商品名称
  courseDuration: ''; // 课节时长
  subject: ''; // 科目
  classType: ''; // 课程类型
  customMessage: { errorMessage: '' }; // 第三方支付信息（失败原因等）
}
export class Limit {
  /**
   * @param min 最小值
   * @param max 最大值
   */
  constructor(
    readonly min: number,
    readonly max: number,
  ) {}

  /**
   * 判断是否在该范围
   * @param value 判断的值
   * @returns 在该范围返回true
   */
  public withIn(value: number): boolean {
    return value >= this.min && value <= this.max;
  }
}
export interface IKlarnaAuthorizeRes {
  /**
   * 授权码
   */
  authorization_token?: string;
  /**
   * 是否了展示表单
   */
  show_form: boolean;
  /**
   * 是否同意付款
   */
  approved: boolean;
}

declare global {
  interface Window {
    klarnaAsyncCallback: () => void;
    Klarna: {
      Payments: {
        init: ({ client_token }: { client_token: string }) => void;
        load: (
          { container, payment_method_category }: { container: string | HTMLElement; payment_method_category?: string },
          callback?: () => void,
        ) => void;
        authorize: (
          {
            payment_method_category,
            auto_finalize,
          }: {
            payment_method_category?: string;
            auto_finalize: boolean;
          },
          data: Record<string, string>,
          callback: (res: IKlarnaAuthorizeRes) => void,
        ) => void;
      };
    };
  }
}

/**
 * klarna可用币种
 */
// export type KlarnaCurrency = Currency.USD | Currency.NZD | Currency.EUR | Currency.GBP | Currency.CHF | Currency.CAD;
export type KlarnaCurrency = Currency.USD | Currency.NZD | Currency.EUR | Currency.GBP | Currency.CAD;

/**
 * klarna可用币种数组
 */
export const KLARNA_CURRENCY: KlarnaCurrency[] = [
  Currency.USD,
  Currency.NZD,
  Currency.EUR,
  Currency.GBP,
  // Currency.CHF,
  Currency.CAD,
];

export interface PaypalDeductionsResp {
  message: {
    details?: {
      issue: string;
      description: string;
    }[];
    message: string;
  };
}

/**
 * klarna可用国家
 */
export type KlarnaCountries =
  | 'US'
  | 'NZ'
  | 'AT'
  | 'BE'
  | 'FI'
  | 'FR'
  | 'DE'
  | 'IE'
  | 'IT'
  | 'NL'
  | 'ES'
  | 'GB'
  | 'CH'
  | 'CA';

/**
 * Klarna国家信息
 */
export interface KlarnaCountryInfo {
  label: string;
  value: KlarnaCountries;
  limit?: Limit;
}

/**
 * klarna金额限制
 */
export const KLARNA_CURRENCY_COUNTRIES: Record<KlarnaCurrency, KlarnaCountryInfo[]> = {
  USD: [
    {
      label: 'United States',
      value: 'US',
      limit: new Limit(35, 4200),
    },
  ],
  NZD: [
    {
      label: 'New Zealand',
      value: 'NZ',
      limit: new Limit(10, 2000),
    },
  ],
  EUR: [
    {
      label: 'Austria',
      value: 'AT',
    },
    {
      label: 'Belgium',
      value: 'BE',
    },
    {
      label: 'Finland',
      value: 'FI',
    },
    {
      label: 'France',
      value: 'FR',
      limit: new Limit(35, 1500),
    },
    {
      label: 'Germany',
      value: 'DE',
    },
    {
      label: 'Ireland (Republic of Ireland)',
      value: 'IE',
      limit: new Limit(35, 1000),
    },
    {
      label: 'Italy',
      value: 'IT',
      limit: new Limit(35, 1500),
    },
    {
      label: 'Netherlands',
      value: 'NL',
      limit: new Limit(35, 4000),
    },
    {
      label: 'Spain',
      value: 'ES',
      limit: new Limit(35, 1500),
    },
  ],
  GBP: [
    {
      label: 'United Kingdom',
      value: 'GB',
      limit: new Limit(30, 1000),
    },
  ],
  // CHF: [
  //   {
  //     label: 'Switzerland',
  //     value: 'CH',
  //   },
  // ],
  CAD: [
    {
      label: 'Canada',
      value: 'CA',
      limit: new Limit(50, 1500),
    },
  ],
};

export interface BankInfo {
  'Bank Code'?: string;
  'Branch Code'?: string;
  'Bank Name'?: string;
  'SWIFT code'?: string;
  'Sort code'?: string;
  'Account Name'?: string;
  'Account Number'?: string;
  'Company Address'?: string;
  'Routing number'?: string;
  'Financial institution number'?: string;
  'Transit number'?: string;
  'CC Code'?: string;
  BSB?: string;
  IBAN?: string;
  BIC?: string;
  [key: string]: any;
}

export interface CurrencyBankInfo {
  bankInfos: BankInfo[];
  default?: BankInfo;
  useAbleCountry: string[];
}

// NZ不走Pingpong，使用默认账户
export const BANK_TRANSFER_ACCOUNT: Record<string, CurrencyBankInfo> = {
  NZD: {
    bankInfos: [
      {
        'Account Name': 'Lan Global Limited',
        'Account Number': '12-3678-0010070-00',
        'Bank Name': 'ASB Bank Limited',
      },
      {
        'Company Address': '7C Antares Place, Rosedale Auckland, 0632, NZ',
      },
    ],
    useAbleCountry: ['NZ'],
  },
  // GBP: {
  //   bankInfos: [
  //     {
  //       'Sort code': '040085',
  //       'Account Number': '00481513',
  //       'Account Name': 'SMART LEARNER INTERNATIONAL CORPORATION',
  //       'Bank Name': 'Modulr FS Limited',
  //     },
  //     {},
  //   ],
  //   useAbleCountry: ['GB'],
  // },
  // todo: 其他国家本地数据
  // HKD: {
  //   bankInfos: [
  //     {
  //       'Account name': 'WUKONG International (Hong Kong) Limited ',
  //       'Account number': '6300171599',
  //       'Bank Code': '007',
  //       'Branch Code': '630',
  //       'Swift Code': 'CHASHKHH',
  //       'Bank Name': 'JPMorgan Chase Bank N.A., Hong Kong Branch',
  //       'Bank Location': 'HONG KONG',
  //       'Account Type': 'Current',
  //       'Company address': 'Room 2609, China Resource Building, 26 Harbour Road, Wanchai, Hong Kong',
  //     },
  //     {},
  //   ],
  //   useAbleCountry: ['HK'],
  // },
  // CAD: {
  //   bankInfos: [
  //     {
  //       'Financial institution number': '',
  //       'Transit number': '',
  //       'CC Code': '',
  //       'Account Number': '',
  //       'Account Name': 'WUKONG INTERNATIONAL (HONG KONG) LTD',
  //       'Bank Name': '',
  //     },
  //     { 'Company Address': 'Room 2609, China Resource Building, 26 Harbour Road, Wanchai, Hong Kong' },
  //   ],
  //   useAbleCountry: ['CA'],
  // },
  // AUD: {
  //   bankInfos: [
  //     {
  //       'Account Name': 'SMART LEARNER INTERNATIONAL CORPORATION',
  //       BSB: '',
  //       'Account Number': '',
  //       'Bank Name': '',
  //     },
  //     {
  //       'Company Address': '340 E Middlefield Rd, Mountain View, CA 94043',
  //     },
  //   ],
  //   useAbleCountry: ['AU'],
  // },
  // EUR: {
  //   bankInfos: [
  //     {
  //       IBAN: '',
  //       'Account Name': 'SMART LEARNER INTERNATIONAL CORPORATION',
  //       BIC: '',
  //       'Bank Name': '',
  //     },
  //     {
  //       'Company Address': '340 E Middlefield Rd, Mountain View, CA 94043',
  //     },
  //   ],
  //   useAbleCountry: [
  //     'DE',
  //     'FR',
  //     'IT',
  //     'NL',
  //     'BE',
  //     'LU',
  //     'IE',
  //     'ES',
  //     'PT',
  //     'AT',
  //     'FI',
  //     'LT',
  //     'LV',
  //     'EE',
  //     'SK',
  //     'SI',
  //     'GR',
  //     'MT',
  //     'CY',
  //   ],
  // },
  // SGB: {
  //   bankInfos: [
  //     {
  //       'SWIFT code': '',
  //       'Account Number': '',
  //       'Account Name': 'SMART LEARNER INTERNATIONAL CORPORATION',
  //       'Bank Name': '',
  //     },
  //     {
  //       'Company Address': '340 E Middlefield Rd, Mountain View, CA 94043',
  //     },
  //   ],
  //   useAbleCountry: ['SG'],
  // },
};

export enum CountryCode {
  NZ = 'NZ',
  US = 'US',
  AU = 'AU',
  // 加拿大
  CA = 'CA',
  // 欧洲
  DE = 'DE',
  FR = 'FR',
  IT = 'IT',
  NL = 'NL',
  BE = 'BE',
  LU = 'LU',
  IE = 'IE',
  ES = 'ES',
  PT = 'PT',
  AT = 'AT',
  FI = 'FI',
  LT = 'LT',
  LV = 'LV',
  EE = 'EE',
  SK = 'SK',
  SI = 'SI',
  GR = 'GR',
  MT = 'MT',
  JP = 'JP',
  // 英国
  GB = 'GB',
  // 香港
  HK = 'HK',
  // 新加坡
  SG = 'SG',
  VA = 'VA',
  GI = 'GI',
  BA = 'BA',
  MK = 'MK',
  ME = 'ME',
  RS = 'RS',
  BG = 'BG',
  RO = 'RO',
  AL = 'AL',
  HR = 'HR',
  SM = 'SM',
  BY = 'BY',
  RU = 'RU',
  UA = 'UA',
  MD = 'MD',
  CH = 'CH',
  PL = 'PL',
  CZ = 'CZ',
  HU = 'HU',
  LI = 'LI',
  MC = 'MC',
  SE = 'SE',
  NO = 'NO',
  IS = 'IS',
  DK = 'DK',
  FO = 'FO',
  AD = 'AD',
  CY = 'CY',
  // OTHERS
  OTHERS = 'HK',
}

export const PingPongCountryCodeToCurrency: { [x: string]: string[] } = {
  NZ: ['NZD'],
  US: ['USD'],
  AU: ['AUD'],
  // 加拿大
  CA: ['CAD'],
  // 欧洲
  DE: ['EUR'],
  FR: ['EUR'],
  IT: ['EUR'],
  NL: ['EUR'],
  BE: ['EUR'],
  LU: ['EUR'],
  IE: ['EUR'],
  ES: ['EUR'],
  PT: ['EUR'],
  AT: ['EUR'],
  FI: ['EUR'],
  LT: ['EUR'],
  LV: ['EUR'],
  EE: ['EUR'],
  SK: ['EUR'],
  SI: ['EUR'],
  GR: ['EUR'],
  MT: ['EUR'],
  CY: ['EUR'],
  // 英国
  GB: ['GBP'],
  // 香港
  HK: ['HKD', 'USD'],
  // 新加坡
  SG: ['SGD', 'USD'],
  // 其他：USD
  OTHERS: ['USD'],
};

export interface AtomeConfig {
  /**
   * 国家名称
   */
  country: string;
  /**
   * 国家代码
   */
  countryCode: string;
  /**
   * 币种
   */
  currency: Currency;
  /**
   * 可用范围
   */
  limit: Limit;
}

/**
 * atome可用币种
 */
export const ATOME_USEABLE_CURRENCIES: AtomeConfig[] = [
  {
    country: 'Singapore',
    countryCode: 'SG',
    currency: Currency.SGD,
    limit: new Limit(10, 8000),
  },
  // {
  //   countryCode: 'ID',
  //   currency: Currency.IDR,
  //   limit: new Limit(50000, 6000000),
  // },
  {
    country: 'Hong Kong, China',
    countryCode: 'HK',
    currency: Currency.HKD,
    limit: new Limit(15, 30000),
  },
  {
    country: 'Malaysia',
    countryCode: 'MY',
    currency: Currency.MYR,
    limit: new Limit(10, 8000),
  },
  // {
  //   country: 'Japan',
  //   countryCode: 'JP',
  //   currency: Currency.JPY,
  //   limit: new Limit(10, 450000),
  // },
];

export type InstallmentMethod = 'ATOME' | 'KLARNA';

export const MONTH_SHORT_EN: Record<number, string> = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sept',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export type AddressDataType = {
  key: string;
  value: string;
};

/**
 * 根据ip获取的地址数据响应数据
 */
export interface AddressFromIpRes {
  countryCode?: any;
  countryOption?: AddressDataType;
  stateOption?: AddressDataType;
  cityOption?: AddressDataType;
}

/**
 * 国家、州、城市信息
 */
export interface AddressListRes {
  uuid: string;
  code?: string;
  name?: string;
  nameAscii?: string;
  countryEn?: string;
  stateEn?: string;
  cityEn?: string;
  iso2?: string;
}

/**
 * 账单信息
 */
export interface BillingInfoPayload {
  orderId: string;
  studentNumber: string;
  payerName: string;
  address: string;
  country: string;
  state: string;
  city: string;
  postcode: string;
}

/**
 * 全局状态数据
 */
export interface GlobalStateType {
  countryCode?: string;
  orderInfo?: OrderProps;
  paymentMethod?: string;
  pageLoading?: boolean;
  pageLoadingOwner?: string;
  specialCountryCode?: string;
  curStudent?: any;
  coupon?: Coupon;
  subscriptionPriceInfo?: any;
  pageLoadingOwnerCollections?: string[];
  billingCountrycode?: string;
}

/**
 * 修改全局状态action
 */
export interface GlobalActionType {
  type?: keyof typeof ActionEnum;
  payload?: GlobalStateType;
}

/**
 * 本地存储billinginfo数据
 */
type CascaderType = { value: string; label: string; code: string };
export interface GlobalBillingInfo {
  payerName: string;
  address: string;
  cascader: {
    country: CascaderType;
    state: CascaderType;
    city: CascaderType;
  };
  postcode: string;
}

export interface PaymentMethodParams {
  method: 'CARD' | 'PAYPAL';
  failRedirectURL: string;
  successRedirectURL: string;
  stateName?: string;
  cityName?: string;
  zipCode?: string;
  street?: string;
  countryName?: string;
  cancelRedirectURL?: string;
}

export interface SubscriptionOptions {
  subscribeConfigId: string; // 相当于订阅课包
  quantity: string | number; // 数量
  currency: string;
  couponId: string | undefined; // 优惠卷Id
  countryCode: string; // 国别号
  // orderData: {},
  eduPlanReq: {
    eduPlanId: string; // 定级信息Id
    frequencyPerWeek: string; // 上课频次
    classType: string; // 课程类型，私教课，公开课
    weekCycle: string; // 扣款频次（周）
    courseDuration: string; // 上课时长
    courseId: string; // 课程Id
    subject: string; // 科目
    level1Id: string; // 课程体系年级
    level2Id: string; // 课程体系单元
    level3Id: string; // 课程体系级别
    subjectCategoryId: string; // 产品系列Id
  };
}

export interface SubscribePricePayload {
  subscribeConfigId: string; // 相当于订阅课包
  quantity: 1; // 数量
  currency: Currency;
  countryCode: string; // 国别号
}

/**
 * 代金劵
 */
export interface Coupon {
  currency: string;
  deductedAmount: number;
  deductibleAmount: number;
  name: string;
  status: string;
  type: string;
  usedTime: any;
  uuid: string;
  whenCreated: string;
  whenModified: string;
  whoCreated: string;
  whoModified: string;
}
export interface BillingEntity {
  uuid: string;
  whenCreated: string;
  whenModified: string;
  whoCreated: string | null;
  whoModified: string | null;
  orderId: string;
  studentNumber: string;
  payerName: string;
  address: string;
  countryId: string | null;
  countryCode: string;
  countryName: string;
  stateId: string | null;
  stateCode: string | null;
  stateName: string;
  cityId: string | null;
  cityName: string;
  postcode: string | null;
}

export enum PaymentPageCode {
  PAY = 'pay',
  HOME = 'home',
  BILLING = 'billing',
}

/**
 * 订单类型 - 常规订单/订阅订单
 */
export enum OrderType {
  ORDER = 'ORDER',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export const recordPaymentTypeKey = 'recordPaymentType';

/**
 * 订阅版本
 * v2 - 订阅2.0
 * v3 - 订阅3.0
 */
export type SubscribeVersion = 'v2' | 'v3';
